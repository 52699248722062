import request from '@/utils/request'


// 查询新-规格曲线值列表
export function listRecord(query) {
  return request({
    url: '/cigarette/curves-record/list',
    method: 'get',
    params: query
  })
}

// 查询新-规格曲线值分页
export function pageRecord(query) {
  return request({
    url: '/cigarette/curves-record/page',
    method: 'get',
    params: query
  })
}

// 查询新-规格曲线值详细
export function getRecord(data) {
  return request({
    url: '/cigarette/curves-record/detail',
    method: 'get',
    params: data
  })
}

// 新增新-规格曲线值
export function addRecord(data) {
  return request({
    url: '/cigarette/curves-record/add',
    method: 'post',
    data: data
  })
}

// 修改新-规格曲线值
export function updateRecord(data) {
  return request({
    url: '/cigarette/curves-record/edit',
    method: 'post',
    data: data
  })
}

// 删除新-规格曲线值
export function delRecord(data) {
  return request({
    url: '/cigarette/curves-record/delete',
    method: 'post',
    data: data
  })
}
